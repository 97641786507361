* {
  padding: 0;
  margin: 0;
}
p {
  font-family: PingFang SC !important;
}
span {
  font-family: PingFang SC;
}
div {
  font-family: PingFang SC;
}
h1,
h2,
h3,
h4 {
  unicode-bidi: normal;
  margin-block: auto;
  margin-inline: auto;
  font-family: PingFang SC;
}
body {
  margin: 0;
  /* font-family: PingFang SC, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */
  font-family: PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
