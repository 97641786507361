@media screen and (min-width: 768px) {
  .fixheader > .container > .nav > .mainav > li > a {
    color: #fff;
  }
  .fixheader > .container > .langs {
    color: #fff;
  }
  .fixheader > .container > .langs > span > a {
    color: #fff;
  }

  .fixheader > .container > .langs a.active::after {
    background-color: #fff;
  }

  .siteHeader {
    overflow: hidden;
    height: 64px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    transition: all 0.2s;
    color: #fff;
  }

  .siteHeader:hover {
    background-color: rgba(16, 21, 5, 0.64);
    backdrop-filter: saturate(180%) blur(20px);
  }

  .overmenu {
    height: 64px;
  }

  .container {
    height: 64px;
    max-width: 1260px;
    margin: 0 auto;
    display: flex;
  }

  .containerNav {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding-left: 48px;
    padding-right: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;
  }

  .log {
    width: 115px;
    padding: 0;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navigation {
    display: flex;
  }
  .nav {
    color: #fff;
  }

  .mainav {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: 1.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .mainav > li {
    position: relative;
    margin: 0 24px;
    height: 100%;
  }
  .mainav > li > a {
    padding: 0;
    height: 64px;
    line-height: 64px;
    font-size: 1rem;
    color: #fff;
  }

  .mainav > li:hover a::after {
    width: 100%;
  }
  .mainav > li:hover a {
    color: rgba(194, 166, 107, 1);
  }
  .mainav > li > a::after {
    content: "";
    background-color: rgba(194, 166, 107, 1);
    width: 0px;
    height: 3px;
    left: 50%;
    top: 95%;
    transform: translateX(-50%);
    z-index: 10;
    position: absolute;
    transition: all 0.2s;
  }

  .search {
    margin: 0 1rem;
    padding: 0 15px;
    height: 64px;
    line-height: 64px;
  }
  .langs {
    font-size: 1rem;
    padding: 0 15px;
    height: 64px;
    line-height: 64px;
  }
  .langs a {
    margin: 0 0.15rem;
    /* margin: 0 0.3rem; */
  }

  .langs a.active {
    font-weight: bold;
    position: relative;
  }
  .langs a.active::after {
    content: " ";
    display: block;
    position: absolute;
    left: 50%;
    bottom: -0.1rem;
    z-index: 10;
    width: 80%;
    height: 2px;
    background-color: #fff;
    transform: translateX(-50%);
  }
  .langs > span > a {
    color: #fff;
  }

  .sub-menu {
    position: absolute;
    width: 154px;
    left: 50%;
    top: 110px;
    z-index: 10;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: -77px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
    margin-top: 0;
  }
  .nav > .mainav > li:hover .sub-menu {
    visibility: visible;
    margin-top: -1rem;
    height: 290px;
    opacity: 1;
  }

  .mainav > li .sub-menu > li a {
    display: block;
    padding: 0.8rem 1rem;
    line-height: 1.5;
    text-align: center;
    color: #3c3c3c;
  }
  .mainav > li .sub-menu > li a:hover {
    background-color: #004e17;
    color: #fff;
  }

  .mainav > .current a::after {
    width: 66px;
  }

  .mainav > .current a {
    width: 66px;
    color: rgba(194, 166, 107, 1);
    font-weight: 600;
  }

  .mainav > .current > a::after {
    content: "";
    background-color: rgba(194, 166, 107, 1);
    width: 66px;
    height: 3px;
    left: 50%;
    top: 95%;
    transform: translateX(-50%);
    z-index: 10;
    position: absolute;
    transition: all 0.2s;
  }
}

/* iPhone X及更高版本 */
@media screen and (max-width: 767px) {
  /* CSS样式 */
  .fixheader > .container > .nav > .mainav > li > a {
    color: #fff;
  }
  .fixheader > .container > .langs {
    color: #fff;
  }
  .fixheader > .container > .langs > span > a {
    color: #fff;
  }

  .fixheader > .container > .langs a.active::after {
    background-color: #fff;
  }

  .fixheader > .container > .log > img {
    display: block;
  }

  .siteHeader {
    overflow: hidden;
    height: 60px;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    transition: all 0.3s;
    color: #fff;
  }

  .overmenu {
    height: 64px;
  }

  .container {
    height: 60px;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
  }

  .containerNav {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
  }

  .log {
    width: 83px;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navigation {
    display: flex;
  }
  .nav {
    padding: 0 15px;
    color: #fff;
  }

  .mainav {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: 1.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .mainav > li {
    position: relative;
    padding: 0 0.8rem;
    height: 100%;
  }
  .mainav > li > a {
    padding: 0 0.5rem;
    height: 64px;
    line-height: 64px;
    font-size: 1rem;
    color: #fff;
  }
  .mainav > li:hover a::after {
    width: 66px;
  }
  .mainav > li:hover a {
    color: rgba(194, 166, 107, 1);
  }
  .mainav > li > a::after {
    content: "";
    background-color: rgba(194, 166, 107, 1);
    width: 0px;
    height: 3px;
    left: 50%;
    top: 75%;
    transform: translateX(-50%);
    z-index: 10;
    position: absolute;
    transition: all 0.3s;
  }

  .search {
    margin: 0 1rem;
    padding: 0 15px;
    height: 64px;
    line-height: 64px;
  }
  .langs {
    font-size: 1rem;
    padding: 0 15px;
    height: 64px;
    line-height: 64px;
  }
  .langs a {
    margin: 0 0.3rem;
  }

  .langs a.active {
    font-weight: bold;
    position: relative;
  }
  .langs a.active::after {
    content: " ";
    display: block;
    position: absolute;
    left: 50%;
    bottom: -0.1rem;
    z-index: 10;
    width: 80%;
    height: 2px;
    background-color: #fff;
    transform: translateX(-50%);
  }
  .langs > span > a {
    color: #fff;
  }

  .sub-menu {
    position: absolute;
    width: 154px;
    left: 50%;
    top: 110px;
    z-index: 10;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: -77px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    margin-top: 0;
  }
  .nav > .mainav > li:hover .sub-menu {
    visibility: visible;
    margin-top: -1rem;
    height: 290px;
    opacity: 1;
  }

  .mainav > li .sub-menu > li a {
    display: block;
    padding: 0.8rem 1rem;
    line-height: 1.5;
    text-align: center;
    color: #3c3c3c;
  }
  .mainav > li .sub-menu > li a:hover {
    background-color: #004e17;
    color: #fff;
  }

  /* 手机 */

  .menuToggle {
    position: relative;
    width: 24px;
    height: auto;
    cursor: default;
    padding: 0 24px;
  }
  .menuToggle > div {
    background-color: rgba(16, 21, 5, 1);
    height: 2px;
    margin: 4px auto;
    backface-visibility: hidden;
    transition-duration: 0.3s;
  }
  .phoneLangs {
    font-size: 1.3rem;
    font-weight: 400;
  }
  .on .one {
    transform: rotate(45deg) translate(3%, 3px);
    background-color: rgba(16, 21, 5, 1);
  }
  .on .two {
    opacity: 0;
  }
  .on .three {
    transform: rotate(-45deg) translate(24%, -8px);
    background-color: rgba(16, 21, 5, 1);
  }
  .bb {
    height: 100%;
    width: 100%;
    background-color: yellow;
    position: fixed;
    left: 0;
    top: 60px;
    background-color: rgba(0, 0, 0, 0);
  }
  .menu-over {
    position: fixed;
    left: 0;
    top: 60px;
    width: 260px;
    height: 100%;
    background-color: #fff;
    opacity: 0.8;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    z-index: 30;
    transform: translate(-260px, 0);
    transition: transform 0.25s ease-out, opacity 0.25s ease-out;
  }
  .showMenu {
    transform: translate(0, 0);
    left: 0;
    opacity: 1;
  }
  .mobileNavItem {
    position: relative;
    transition: all 0.2s;
    border-bottom: 1px rgba(194, 166, 107, 0.15) solid;
    box-sizing: border-box;
    padding: 24px;
    padding-left: 25px;
    font-size: 1.2rem;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobileNavItem > .itemImg {
    width: 9px;
    height: 16px;
    /* width: 0.5rem;
    height: 0.5rem; */
  }
}
